/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 7px;
}
::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
    display: none;
} 
::-webkit-scrollbar:horizontal {
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #CCC;
    border-radius: 20px;
    border: 1px solid #FFF;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
}
span.select2 {
    color: #717171;
    font-size: 1rem;
    transition: box-shadow .15s ease;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    width: 100% !important;
}

.select2-selection{
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
}
.select2-selection span{
    padding: 20px !important;
}


.role-list label {
    font-size: .8rem;
    font-weight: bold;
}

.role-list .menu-tree  {    
    padding-left: 1.5rem;
}

.role-list .menu-subtree  {    
    padding-left: 1rem;
}

.role-list .menu-tree span {    
    font-size: .9rem;
}

.mat-datepicker-input {
    width: calc(100% - 50px) !important;
}

.position-control .control {
    display: inline-block;
    vertical-align: middle;
    padding-left: .5rem;
}

.position-control .control button {
    display: block;
    padding: 0;
    border: 0;
    border-radius: 0;
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
}
.position-control .control button .mat-icon {
    font-size: 20px;
}
.position-control .control button:hover {
    background: rgb(76, 153, 180);
    color: #fff;
}